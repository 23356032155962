
// COMPONENTS

// APP
import { Actions } from "@/store/enums/StoreEnums";
import { defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
	name: "ProfileDetails",
	props: {
		userId: {
			type: String,
			required: true,
		},
	},
	setup(props) {
		const store = useStore();

		let user = ref(null);

		onMounted(async () => {
			setCurrentPageBreadcrumbs("Overview", ["Account"]);

			let userProfile = await store.dispatch(Actions.USER_PROFILE, { user_id: props.userId });

//mp:			if (userProfile != undefined && userProfile.hasOwnProperty("code") && userProfile.code == 200) {
			if (Object.prototype.hasOwnProperty.call(userProfile, 'code') && userProfile.code == 200) {
				user.value = userProfile.data;
/*				console.log(userProfile, "Boom");*/
			}
		});

		return {
			user,
		};
	},
});
